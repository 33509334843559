import {
  Banner,
  Bleed,
  BlockStack,
  Card,
  Divider,
  FormLayout,
  Layout,
  Modal,
  Page,
  Text,
  TextField,
  InlineStack,
  Button,
  Box,
} from "@shopify/polaris";
import {
  notEmpty,
  useDynamicList,
  useField,
  useForm,
} from "@shopify/react-form";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { CurrentContactContext } from "../../contexts/Contact";
import {
  calculateInterestRate,
  calculatePaymentAndDiscount,
  roundedDollarAmount,
} from "../../finance.ts";
import {
  isValidCurrency,
  toHumanReadableFinanceType,
  uuidv4,
} from "../../utilities";

import ApprovedBanner from "./ApprovedBanner";
import CalculatorModeToggle from "./CalculatorModeToggle";
import SelectedIndicator from "./SelectedIndicator";

import BaseSegmentFields from "./BaseSegmentFields";
import DiscountFields from "./DiscountFields";
import DownPaymentFields from "./DownPaymentFields";
import InstallmentPaymentFields from "./InstallmentPaymentFields";
import InterestRateFields from "./InterestRateFields";
import PurchaseOptionSegmentFields from "./PurchaseOptionSegmentFields";
import BalloonPaymentFields from "./BalloonPaymentFields";
import TimingFields from "./TimingFields";

import AdminSummaryCard from "./AdminSummary";
import FinanceSummaryCard from "./FinanceSummary";
import OverviewCard from "./OverviewCard";
import SummaryCard from "./Summary";

const PurchaseOptionCalculator = (props) => {
  const {
    onBackAction = () => {},
    onSaveAction,
    opportunity = { attributes: {} },
    purchaseOption = { attributes: {} },
    purchaseOptionType,
    purchaseOptionSegments = [],
  } = props;

  const {
    isOrganizationAdmin,
    isFinanceSpecialist,
    currentContactHasPermission,
  } = useContext(CurrentContactContext);

  const history = useHistory();

  const clientId = opportunity.attributes.client_id;
  const opportunityId = opportunity.id;
  const navigateToTransactions = useCallback(() => {
    history.push(
      `/clients/${clientId}/opportunities/${opportunityId}?tab=payments-n-review`
    );
  }, [clientId, history, opportunityId]);

  const navigateToTransaction = useCallback(
    (id) => {
      history.push(
        `/clients/${clientId}/opportunities/${opportunityId}?tab=payments-n-review&transaction_id=${id}`
      );
    },
    [clientId, history, opportunityId]
  );

  // Calculator settings
  const [solvingMode, setSolvingMode] = useState("payment");
  const [hasDownPayment, setHasDownPayment] = useState(false);
  const [downPaymentCompletedAt, setDownPaymentCompletedAt] = useState();

  const [hasBalloonPayment, setHasBalloonPayment] = useState(false);
  const [balloonPaymentCompletedAt, setBalloonPaymentCompletedAt] = useState();

  // Opportunity attributes
  const [totalFinanceAmount, setTotalFinanceAmount] = useState();
  const [totalTermLength, setTotalTermLength] = useState();
  const [baseTermLength, setBaseTermLength] = useState();
  const [lenderBuyRate] = useState(purchaseOption?.attributes?.lender_buy_rate);
  const [invoiceTotal, setInvoiceTotal] = useState(
    purchaseOption?.attributes?.total_finance_amount ||
      opportunity?.attributes?.total_finance_amount
  );

  // Purchase option attributes
  const [installmentPayments, setInstallmentPayments] = useState(
    purchaseOption?.attributes?.installment_payments || []
  );
  const [buyRatePayment, setBuyRatePayment] = useState();
  const [sellRatePayment, setSellRatePayment] = useState();
  const [customerPayment, setCustomerPayment] = useState();
  const [buyRateDiscount, setBuyRateDiscount] = useState();
  const [sellRateDiscount, setSellRateDiscount] = useState();
  const [customerDiscount, setCustomerDiscount] = useState();

  const [netPayment, setNetPayment] = useState();

  const [usesMilestonePayments, setUsesMilestonePayments] = useState(
    purchaseOption?.attributes?.uses_milestone_payments || false
  );

  const includeDownPayment = [
    "standard",
    "fair_market_value",
    "dollar_out_lease",
    "saas",
  ];

  const includeBalloonPayment = ["rental", "fair_market_value"];

  useEffect(() => {
    if (purchaseOption.attributes.down_payment) {
      setHasDownPayment(true);
    }

    if (purchaseOption.attributes.balloon_payment) {
      setHasBalloonPayment(true);
    }

    if (purchaseOption.attributes.down_payment_completed_at) {
      setDownPaymentCompletedAt(
        purchaseOption.attributes.down_payment_completed_at
      );
    }
  }, [
    purchaseOption.attributes.balloon_payment,
    purchaseOption.attributes.down_payment,
    purchaseOption.attributes.down_payment_completed_at,
  ]);

  const timingFields = {
    period: useField({
      value: "monthly",
      validates: notEmpty("Period is required"),
    }),
    payment_type: useField({
      value: purchaseOption.attributes.payment_type || "advance",
      validates: notEmpty("Payment type is required"),
    }),
  };

  const interestRateFields = {
    estimated_lender_buy_rate: useField({
      value:
        lenderBuyRate ||
        purchaseOption?.attributes?.estimated_lender_buy_rate ||
        "8.99",
      validates: [],
    }),

    buy_rate: useField({
      value:
        purchaseOption.attributes.buy_rate ||
        lenderBuyRate ||
        purchaseOption?.attributes?.estimated_lender_buy_rate ||
        "8.99",
      validates: [],
    }),

    sell_rate: useField({
      value:
        purchaseOption.attributes.sell_rate ||
        opportunity.attributes.default_standard_interest_rate ||
        5,
      validates: [notEmpty("Interest rate is required")],
    }),

    customer_interest_rate: useField({
      value:
        purchaseOption.attributes.customer_interest_rate ||
        opportunity.attributes.default_standard_interest_rate ||
        5,
      validates: [notEmpty("Customer interest rate is required")],
    }),

    interest_rate_visible: useField(
      purchaseOption.attributes.interest_rate_visible
    ),
  };

  const discountFields = {
    discount: useField({
      value: purchaseOption.attributes.discount,
      validates: [],
    }),
    discount_visible: useField(purchaseOption.attributes.discount_visible),
  };

  const downPaymentFields = {
    down_payment: useField({
      value: purchaseOption.attributes.down_payment,
      validates: [],
    }),
    down_payment_percent: useField({
      value: purchaseOption?.attributes?.down_payment_percent || 0,
      validates: [
        (value) => {
          const totalPercent = installmentPaymentFields.reduce(
            (sum, field) => sum + (field.percent?.value || 0),
            0
          );
          const remainingPercent = 100 - totalPercent;
          if (value > remainingPercent) {
            return "Total cannot exceed 100%";
          }
        },
      ],
    }),
  };

  const balloonPaymentFields = {
    balloon_payment: useField({
      value: purchaseOption?.attributes?.balloon_payment || 0,
      validates: [],
    }),
  };

  const installmentPaymentFactory = (params) => {
    const {
      fields: installmentPaymentFields,
      removeInstallmentPayments,
      fieldsToAdd,
    } = params;

    if (fieldsToAdd) {
      let fields = fieldsToAdd.map((field) => ({
        ...field,
        id: field.id || uuidv4(),
        status: field.status || "pending",
      }));

      return fields;
    }

    const paymentAmount = calculateInstallmentPaymentAmount(
      installmentPaymentFields.length + 1
    );

    if (installmentPaymentFields.length || hasDownPayment) {
      let indexes = [];
      const _totalFinanceAmount = calculateTotalFinanceAmount();
      const existingInstallmentPayments = installmentPaymentFields.map(
        (payment, index) => {
          indexes.push(index);
          let adjustedPercent = 0;
          let percentAmount = 0;

          if (usesMilestonePayments && payment?.percent?.value) {
            const percentValue = payment?.percent?.value;
            adjustedPercent =
              percentValue >= 1 ? percentValue : percentValue * 100;
            percentAmount = adjustedPercent * 0.01 * _totalFinanceAmount;
          }

          return {
            days: payment.days.value,
            percent: adjustedPercent,
            amount: usesMilestonePayments
              ? roundedDollarAmount(percentAmount)
              : paymentAmount,
            id: payment.id.value,
            status: payment.status.value,
          };
        }
      );
      removeInstallmentPayments(indexes);

      const newInstallmentPayments = [
        ...existingInstallmentPayments,
        {
          days: 1,
          percent: 0,
          id: uuidv4(),
          status: "pending",
          amount: usesMilestonePayments ? 0 : paymentAmount,
        },
      ];

      const paymentLength = newInstallmentPayments.length;
      const lastPayment = newInstallmentPayments[paymentLength - 1];

      if (usesMilestonePayments) {
        let parts = paymentLength;
        if (hasDownPayment) parts += 1;

        let evenPartPercentage = parseFloat((100 / parts).toFixed(0));
        let totalPercentage = 100;
        let j = 0;

        for (let i = 1; i <= parts; i++) {
          if (hasDownPayment && i == 1) {
            // Down payment
            // Set part percentage on down payment field

            // downPaymentFields.down_payment_percent.value = evenPartPercentage;
            // downPaymentFields.down_payment.value =
            //   evenPartPercentage * 0.01 * _totalFinanceAmount;

            downPaymentFields.down_payment_percent.onChange(evenPartPercentage);
            downPaymentFields.down_payment.onChange(
              evenPartPercentage * 0.01 * _totalFinanceAmount
            );
          } else if (i == parts) {
            // Last installment payment
            // Set remainder
            newInstallmentPayments[j].percent = totalPercentage;
            newInstallmentPayments[j].amount =
              totalPercentage * 0.01 * _totalFinanceAmount;
          } else {
            // Middle installment payments
            // Set part percentage
            newInstallmentPayments[j].percent = evenPartPercentage;
            newInstallmentPayments[j].amount =
              evenPartPercentage * 0.01 * _totalFinanceAmount;

            j += 1;
          }

          totalPercentage = totalPercentage - evenPartPercentage;
        }
      }

      const newInstallmentPaymentsTotal = newInstallmentPayments.reduce(
        (total, payment) => {
          return total + payment.amount;
        },
        0
      );

      let lastPaymentRemainder = roundedDollarAmount(
        totalFinanceAmount - newInstallmentPaymentsTotal
      );
      if (lastPayment && !usesMilestonePayments) {
        lastPayment.amount = roundedDollarAmount(
          newInstallmentPayments[newInstallmentPayments.length - 1].amount +
            lastPaymentRemainder
        );
      }

      return newInstallmentPayments;
    } else {
      let percent = 0;
      let days = usesMilestonePayments ? 0 : 1;
      let amount = 0;

      if (usesMilestonePayments && hasDownPayment) {
        percent = 100 - (downPaymentFields?.down_payment_percent?.value || 0);
        amount = roundedDollarAmount(percent * 0.01 * totalFinanceAmount);
      } else {
        percent = usesMilestonePayments ? 100 : 0;
        amount = usesMilestonePayments ? totalFinanceAmount : paymentAmount;
      }

      return [
        {
          days: days,
          amount: roundedDollarAmount(amount),
          id: uuidv4(),
          status: "pending",
          percent: percent,
        },
      ];
    }
  };

  const {
    fields: installmentPaymentFields,
    addItem: addInstallmentPayment,
    removeItem: removeInstallmentPayment,
    removeItems: removeInstallmentPayments,
  } = useDynamicList(
    {
      list: installmentPayments,
      validates: {
        days: (count) => {
          if (count < 1 && !usesMilestonePayments)
            return "Number of days must be greater than 0";
        },
        percent: (value) => {
          if (value < 0 || value > 100) {
            return "Percent must be between 0 and 100";
          }

          const downPaymentPercent =
            downPaymentFields?.down_payment_percent?.value || 0;
          const totalPercent =
            installmentPaymentFields.reduce(
              (sum, field) => sum + (field.percent?.value || 0),
              0
            ) + downPaymentPercent;

          if (totalPercent > 100) {
            return "Total percent cannot exceed 100%";
          }
        },
      },
    },
    installmentPaymentFactory
  );

  useEffect(() => {
    return installmentPaymentFields.runValidation;
  }, [
    installmentPaymentFields.runValidation,
    downPaymentFields.down_payment_percent.value,
  ]);

  const purchaseOptionSegmentFactory = () => ({
    count: 6,
    value: "99",
    _destroy: false,
  });

  const existingPurchaseOptionSegments = purchaseOptionSegments.map(
    (segment) => {
      return {
        ...segment.attributes,
        id: segment.id,
        _destroy: false,
      };
    }
  );
  const {
    fields: purchaseOptionSegmentFields,
    addItem: addPurchaseOptionSegment,
  } = useDynamicList(
    {
      list: existingPurchaseOptionSegments,
      validates: {
        count: (count) => {
          if (count < 1 && !usesMilestonePayments)
            return "Number of segments must be greater than 0";
        },
        value: [
          notEmpty("Amount is required"),
          (value) => isValidCurrency(value),
        ],
      },
    },
    purchaseOptionSegmentFactory
  );
  const handleRemovePurchaseOptionSegment = useCallback((field) => {
    field._destroy.onChange(true);
  }, []);

  const baseSegmentFields = {
    base_payment: useField({
      value: purchaseOption.attributes.base_payment,
      validates: [
        notEmpty("Base payment is required."),
        (value) => {
          if (value < 0) {
            return "Base payment must be positive.";
          }
        },
      ],
    }),
    base_term_length: useField(
      {
        value: baseTermLength,
        validates: [
          notEmpty("Base term length is required"),
          () => {
            if (
              totalTermLength <
                opportunity.attributes.default_term_length_min &&
              purchaseOptionType !== "net_terms"
            ) {
              return `Total term length must be ${opportunity.attributes.default_term_length_min} or more.`;
            }
          },
          () => {
            if (
              totalTermLength >
                opportunity.attributes.default_term_length_max &&
              purchaseOptionType !== "net_terms"
            ) {
              return `Total term length must be ${opportunity.attributes.default_term_length_max} or less.`;
            }
          },
          (value) => {
            if (
              (value <= 0 || value === "0") &&
              purchaseOptionType !== "net_terms"
            ) {
              return "Base term length must be greater than 0";
            }
          },
        ],
      },
      [totalTermLength, purchaseOptionSegmentFields]
    ),
  };

  const residualValueFields = {
    residual_value: useField({
      value: purchaseOption?.attributes?.residual_value || 0,
      validates: [],
    }),
  };

  const fieldsByPaymentOptionType = () => {
    let returnFields = {};
    const baseFields = {
      ...baseSegmentFields,
      ...timingFields,
      ...interestRateFields,
      purchase_option_segments: purchaseOptionSegmentFields,
      ...residualValueFields,
    };

    returnFields = {
      ...baseFields,
    };

    if (["net_terms"].includes(purchaseOptionType)) {
      return {
        ...baseFields,
        ...downPaymentFields,
        ...discountFields,
        installment_payments: installmentPaymentFields,
      };
    }

    if (includeDownPayment.includes(purchaseOptionType)) {
      returnFields = {
        ...returnFields,
        ...downPaymentFields,
      };
    }

    if (includeBalloonPayment.includes(purchaseOptionType)) {
      returnFields = {
        ...returnFields,
        ...balloonPaymentFields,
      };
    }

    // Including residual value for all option as it is computationally valud
    //
    // if (["fair_market_value"].includes(purchaseOptionType)) {
    //   returnFields = {
    //     ...returnFields,
    //     ...residualValueFields,
    //   }
    // }

    return returnFields;
  };

  const { fields, submit, isSubmitting, submitErrors } = useForm({
    fields: fieldsByPaymentOptionType(),
    async onSubmit(form) {
      // add fields from calculated values
      form.amount_to_fund = netPayment;
      form.blind_discount = customerDiscount;
      form.term_length = totalTermLength;
      form.financing_type = toHumanReadableFinanceType[purchaseOptionType];
      form.financing_option_segments_attributes = form.purchase_option_segments;

      // remove unnecessary payload fields
      delete form.base_term_length;
      delete form.period;
      delete form.purchase_option_segments;

      if (!hasDownPayment) {
        form.down_payment = null;
      }

      if (!hasBalloonPayment) {
        form.balloon_payment = null;
      }

      // profit
      return onSaveAction(form);
    },
  });

  const handleBasePaymentChange = useCallback(
    (value) => {
      if (value !== customerPayment) {
        fields.base_payment.onChange(value);
        setCustomerPayment(value);
      }
    },
    [fields.base_payment, setCustomerPayment, customerPayment]
  );

  const handleCustomerInterestRateChange = useCallback(
    (value) => {
      if (value !== fields.customer_interest_rate.value) {
        fields.customer_interest_rate.onChange(value);
      }
    },
    [fields.customer_interest_rate]
  );

  const handleRemoveInstallmentPayment = useCallback(
    (index) => {
      removeInstallmentPayment(index);

      const existingInstallmentPayments = [...fields.installment_payments];

      const remainingInstallmentPayments = existingInstallmentPayments.filter(
        (_, i) => i !== index
      );

      const indexes = [];

      const paymentAmount = calculateInstallmentPaymentAmount(
        remainingInstallmentPayments.length
      );

      const remainingInstallmentPaymentsFormatted =
        remainingInstallmentPayments.map((payment, index) => {
          indexes.push(index);
          let adjustedPercent = 0;
          let percentAmount = 0;

          if (usesMilestonePayments && payment?.percent?.value) {
            const percentValue = payment?.percent?.value;
            adjustedPercent =
              percentValue > 1 ? percentValue : percentValue * 100;
            percentAmount = adjustedPercent * 0.01 * totalFinanceAmount;
          }

          return {
            days: payment.days.value,
            amount: usesMilestonePayments
              ? roundedDollarAmount(percentAmount)
              : paymentAmount,
            percent: adjustedPercent,
            id: payment.id.value,
            status: payment.status.value,
          };
        });

      removeInstallmentPayments(indexes);

      // If there are no remaining installment payments, do nothing
      if (existingInstallmentPayments.length === 0 && !hasDownPayment) {
        return;
      }

      const lastPayment =
        remainingInstallmentPaymentsFormatted[
          remainingInstallmentPaymentsFormatted.length - 1
        ];

      if (usesMilestonePayments) {
        let parts = remainingInstallmentPaymentsFormatted.length;
        if (hasDownPayment) parts += 1;

        let evenPartPercentage = parseFloat((100 / parts).toFixed(0));
        let totalPercentage = 100;
        let j = 0;

        for (let i = 1; i <= parts; i++) {
          if (hasDownPayment && i == 1) {
            // Down payment
            // Set part percentage on down payment field
            downPaymentFields.down_payment_percent.onChange(evenPartPercentage);
            downPaymentFields.down_payment.onChange(
              evenPartPercentage * 0.01 * totalFinanceAmount
            );
          } else if (i == parts) {
            // Last installment payment
            // Set remainder
            remainingInstallmentPaymentsFormatted[j].percent = totalPercentage;
            remainingInstallmentPaymentsFormatted[j].amount =
              totalPercentage * 0.01 * totalFinanceAmount;
          } else {
            // Middle installment payments
            // Set part percentage
            remainingInstallmentPaymentsFormatted[j].percent =
              evenPartPercentage;
            remainingInstallmentPaymentsFormatted[j].amount =
              evenPartPercentage * 0.01 * totalFinanceAmount;

            j += 1;
          }

          totalPercentage = totalPercentage - evenPartPercentage;
        }
      }

      const newInstallmentPaymentsTotal =
        remainingInstallmentPaymentsFormatted.reduce((total, payment) => {
          return total + payment.amount;
        }, 0);

      let lastPaymentRemainder = roundedDollarAmount(
        totalFinanceAmount - newInstallmentPaymentsTotal
      );
      // const lastPayment =
      //   remainingInstallmentPaymentsFormatted[
      //     remainingInstallmentPaymentsFormatted.length - 1
      //   ];
      if (lastPayment && !usesMilestonePayments) {
        lastPayment.amount = roundedDollarAmount(
          remainingInstallmentPaymentsFormatted[
            remainingInstallmentPaymentsFormatted.length - 1
          ].amount + lastPaymentRemainder
        );
      }

      addInstallmentPayment({
        fieldsToAdd: remainingInstallmentPaymentsFormatted,
      });
    },
    [
      removeInstallmentPayment,
      fields.installment_payments,
      calculateInstallmentPaymentAmount,
      removeInstallmentPayments,
      usesMilestonePayments,
      totalFinanceAmount,
      addInstallmentPayment,
      hasDownPayment,
      downPaymentFields.down_payment_percent,
      downPaymentFields.down_payment,
    ]
  );

  const calculateInstallmentPaymentAmount = useCallback(
    (installmentPaymentCount = 1) => {
      const paymentAmount =
        installmentPaymentCount > 0
          ? totalFinanceAmount / installmentPaymentCount
          : totalFinanceAmount;

      return paymentAmount ? roundedDollarAmount(paymentAmount) : 0;
    },
    [totalFinanceAmount]
  );

  const calculateTotalTermLength = (baseTermLength, purchaseOptionSegments) => {
    const totalPurchaseOptionSegmentsTermLength = purchaseOptionSegments.reduce(
      (total, segment) => {
        return Number(total) + Number(segment.attributes.count);
      },
      0
    );

    return (
      (Number(baseTermLength) || 0) +
      (Number(totalPurchaseOptionSegmentsTermLength) || 0)
    );
  };

  const calculateTotalFinanceAmount = useCallback(() => {
    const discountAmount = (fields?.discount?.value || 0) * invoiceTotal;
    let totalAmount = invoiceTotal - discountAmount;

    if (!usesMilestonePayments) {
      const downPayment = hasDownPayment ? fields?.down_payment?.value || 0 : 0;
      const balloonPayment = hasBalloonPayment
        ? fields?.balloon_payment?.value || 0
        : 0;
      totalAmount = totalAmount - (downPayment + balloonPayment);
    }

    return totalAmount;
  }, [
    fields?.discount?.value,
    fields?.down_payment?.value,
    fields?.balloon_payment?.value,
    hasDownPayment,
    hasBalloonPayment,
    invoiceTotal,
    usesMilestonePayments,
  ]);

  const recalculateInstallmentPaymentAmounts = useCallback(() => {
    let _totalFinanceAmount = totalFinanceAmount;
    let zeroPercents = false;
    const paymentAmount = calculateInstallmentPaymentAmount(
      installmentPaymentFields.length
    );

    if (isNaN(_totalFinanceAmount)) {
      _totalFinanceAmount = calculateTotalFinanceAmount();
    }
    if (usesMilestonePayments) {
      let totalPercent = installmentPaymentFields.reduce((acc, field) => {
        return acc + Number(field.percent.value * 100);
      }, 0);
      zeroPercents = totalPercent < 1;
    }

    let indexesToRemove = [];
    const newInstallmentPayments = installmentPaymentFields.map(
      (payment, index) => {
        indexesToRemove.push(index);
        let adjustedPercent = 0;
        let percentAmount = 0;

        if (
          usesMilestonePayments &&
          (payment?.percent?.value || zeroPercents)
        ) {
          const percentValue = payment?.percent?.value;
          adjustedPercent =
            percentValue > 1 ? percentValue : percentValue * 100;

          if (zeroPercents) {
            const totalPaymentCount =
              installmentPaymentFields.length + (hasDownPayment ? 1 : 0);
            adjustedPercent = 100 / totalPaymentCount;
            adjustedPercent = Number(adjustedPercent.toFixed(0));

            if (installmentPaymentFields.length - 1 == index) {
              adjustedPercent = 100 - adjustedPercent * (totalPaymentCount - 1);
            }
          }
          percentAmount = adjustedPercent * 0.01 * _totalFinanceAmount;
        }

        return {
          days: payment?.days?.value || 0,
          percent: adjustedPercent,
          amount: usesMilestonePayments
            ? roundedDollarAmount(percentAmount)
            : paymentAmount,
          id: payment.id?.value || uuidv4(),
          status: payment.status?.value || "pending",
        };
      }
    );

    // Include down payment adjustment
    if (hasDownPayment && usesMilestonePayments) {
      let percentAmount = downPaymentFields.down_payment_percent.value;
      downPaymentFields.down_payment.onChange(
        percentAmount * 0.01 * totalFinanceAmount
      );
    }

    const newInstallmentPaymentsTotal = newInstallmentPayments.reduce(
      (total, payment) => {
        return total + (payment?.amount || 0);
      },
      0
    );

    const lastPayment =
      newInstallmentPayments[newInstallmentPayments.length - 1];
    if (lastPayment && !usesMilestonePayments) {
      let lastPaymentRemainder = roundedDollarAmount(
        _totalFinanceAmount - newInstallmentPaymentsTotal
      );
      lastPayment.amount += lastPaymentRemainder;
    }

    removeInstallmentPayments(indexesToRemove);
    addInstallmentPayment({
      fieldsToAdd: newInstallmentPayments,
    });
  }, [
    totalFinanceAmount,
    calculateInstallmentPaymentAmount,
    installmentPaymentFields,
    usesMilestonePayments,
    removeInstallmentPayments,
    addInstallmentPayment,
    calculateTotalFinanceAmount,
    hasDownPayment,
  ]);

  const initializeCalculator = () => {
    // Initialize total amount based on purchase option,
    // fall back to opportunity
    if (
      purchaseOption.attributes.total_finance_amount ||
      opportunity.attributes.total_finance_amount
    ) {
      setInvoiceTotal(
        purchaseOption.attributes.total_finance_amount ||
          opportunity.attributes.total_finance_amount
      );
      setNetPayment(
        purchaseOption.attributes.total_finance_amount ||
          opportunity.attributes.total_finance_amount
      );
      const calculatedTotalFinanceAmount = calculateTotalFinanceAmount();
      setTotalFinanceAmount(calculatedTotalFinanceAmount);
    }

    const initialTotalTermLength = purchaseOption.attributes.term_length;
    setTotalTermLength(initialTotalTermLength);

    if (purchaseOptionSegments.length > 0) {
      const totalPurchaseOptionSegmentsTermLength =
        purchaseOptionSegments.reduce(
          (total, segment) => total + segment.attributes.count,
          0
        );

      const baseTermLength =
        initialTotalTermLength - totalPurchaseOptionSegmentsTermLength;
      setBaseTermLength(baseTermLength);
    } else {
      setBaseTermLength(initialTotalTermLength);
    }
  };

  // initializer hook
  useEffect(() => {
    initializeCalculator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Validate the total term length when the base term length changes
  useEffect(() => {
    if (fields.base_term_length.value && totalTermLength) {
      fields.base_term_length.runValidation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalTermLength, fields.base_term_length.value]);

  useEffect(() => {
    /************************************************************
     *           Run calculations when values change...
     ************************************************************/

    // Format the purchase option segments for the finance calculator
    const purchaseOptionSegmentList = fields.purchase_option_segments
      .filter((segment) => {
        return segment._destroy.value !== true;
      })
      .map((segment) => {
        return {
          attributes: {
            count: segment.count.value,
            value: segment.value.value,
          },
        };
      });

    const calculatedTotalFinanceAmount = calculateTotalFinanceAmount();

    if (calculatedTotalFinanceAmount != totalFinanceAmount) {
      setTotalFinanceAmount(calculatedTotalFinanceAmount);
    }

    const discountAmount = (fields?.discount?.value || 0) * invoiceTotal;

    // Calculate the total term length
    const calculatedTotalTermLength = calculateTotalTermLength(
      Number(fields.base_term_length.value),
      purchaseOptionSegmentList
    );
    setTotalTermLength(calculatedTotalTermLength);

    if (solvingMode === "payment") {
      /************************************************************
       *                 Solve for payment...
       ************************************************************/

      // Solve for the lender rate - buy rate spread
      const buyRateSpreadUpdate = calculatePaymentAndDiscount(
        totalFinanceAmount,
        lenderBuyRate || fields.estimated_lender_buy_rate?.value,
        fields.buy_rate?.value,
        calculatedTotalTermLength,
        fields.payment_type?.value,
        purchaseOptionSegmentList,
        fields.residual_value?.value
      );

      // Solve for the buy rate - sell rate spread
      const sellRateSpreadUpdate = calculatePaymentAndDiscount(
        totalFinanceAmount,
        fields.buy_rate?.value,
        fields.sell_rate?.value,
        calculatedTotalTermLength,
        fields.payment_type?.value,
        purchaseOptionSegmentList,
        fields.residual_value?.value
      );

      // Solve for the sell rate - customer rate spread
      const customerInterestRateSpreadUpdate = calculatePaymentAndDiscount(
        totalFinanceAmount,
        fields.sell_rate?.value,
        fields.customer_interest_rate?.value,
        calculatedTotalTermLength,
        fields.payment_type?.value,
        purchaseOptionSegmentList,
        fields.residual_value?.value
      );

      const { payment: buyRatePayment, discount: buyRateDiscount } =
        buyRateSpreadUpdate;
      const { payment: sellRatePayment, discount: sellRateDiscount } =
        sellRateSpreadUpdate;
      const { payment: customerPayment, discount: customerDiscount } =
        customerInterestRateSpreadUpdate;

      if (
        (lenderBuyRate || fields.estimated_lender_buy_rate.value) !=
        fields.buy_rate.value
      ) {
        setBuyRateDiscount(buyRateDiscount);
      } else {
        setBuyRateDiscount(0);
      }
      setBuyRatePayment(buyRatePayment);

      if (fields.buy_rate.value != fields.sell_rate.value) {
        setSellRateDiscount(sellRateDiscount);
      } else {
        setSellRateDiscount(0);
      }
      setSellRatePayment(sellRatePayment);

      handleBasePaymentChange(customerPayment);

      if (fields.sell_rate.value != fields.customer_interest_rate.value) {
        if (customerDiscount > 0) {
          setCustomerDiscount(customerDiscount);
          setNetPayment(invoiceTotal - customerDiscount - discountAmount);
        // } else if (
        //   purchaseOption?.attributes?.blind_discount &&
        //   purchaseOption?.attributes?.blind_discount > 0
        // ) {
        //   setCustomerDiscount(purchaseOption?.attributes?.blind_discount);
        //   setNetPayment(
        //     invoiceTotal -
        //       purchaseOption?.attributes?.blind_discount -
        //       discountAmount
        //   );
        } else {
          setCustomerDiscount(0);
          setNetPayment(invoiceTotal - discountAmount);
        }
      } else {
        setCustomerDiscount(0);
        setNetPayment(invoiceTotal - discountAmount);
      }
    } else if (solvingMode === "rate") {
      /************************************************************
       *                 Solve for interest rate...
       ************************************************************/
      const customerInterestRate = calculateInterestRate(
        totalFinanceAmount,
        fields.base_payment.value,
        calculatedTotalTermLength,
        fields.payment_type.value,
        purchaseOptionSegmentList
      );
      customerInterestRate &&
        handleCustomerInterestRateChange(customerInterestRate);

      const customerInterestRateSpreadUpdate = calculatePaymentAndDiscount(
        totalFinanceAmount,
        fields.sell_rate?.value,
        customerInterestRate,
        calculatedTotalTermLength,
        fields.payment_type?.value,
        purchaseOptionSegmentList,
        fields.residual_value?.value
      );
      const { discount: customerDiscount } = customerInterestRateSpreadUpdate;

      if (fields.sell_rate.value != customerInterestRate) {
        if (customerDiscount > 0) {
          setCustomerDiscount(customerDiscount);
          setNetPayment(invoiceTotal - customerDiscount);
        } else {
          setCustomerDiscount(0);
          setNetPayment(invoiceTotal);
        }
      } else {
        setCustomerDiscount(0);
        setNetPayment(invoiceTotal);
      }
    }
  }, [
    lenderBuyRate,
    fields.estimated_lender_buy_rate.value,
    fields.buy_rate.value,
    fields.payment_type.value,
    fields.sell_rate.value,
    fields.customer_interest_rate.value,
    fields.base_term_length.value,
    fields.purchase_option_segments,
    fields.base_payment.value,
    fields?.discount?.value,
    fields?.residual_value?.value,
    usesMilestonePayments,
    setUsesMilestonePayments,
    existingPurchaseOptionSegments,
    solvingMode,
    totalFinanceAmount,
    handleBasePaymentChange,
    handleCustomerInterestRateChange,
    calculateTotalFinanceAmount,
    invoiceTotal,
  ]);

  useEffect(() => {
    recalculateInstallmentPaymentAmounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalFinanceAmount, usesMilestonePayments, customerDiscount]);

  const handleChangePaymentStructure = useCallback(
    (value) => {
      setUsesMilestonePayments(value);
    },
    [setUsesMilestonePayments]
  );

  const [showEditAdminSummaryModal, setShowEditAdminSummaryModal] =
    useState(false);
  const editAdminSummaryModal = (
    <Modal
      open={showEditAdminSummaryModal}
      title={"Admin parameters"}
      primaryAction={{
        content: "Save",
        onAction: () => setShowEditAdminSummaryModal(false),
      }}
      onClose={() => setShowEditAdminSummaryModal(false)}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              label="Lender buy rate"
              suffix="%"
              {...fields.estimated_lender_buy_rate}
              disabled={lenderBuyRate}
            />
            <TextField label="Buy rate" suffix="%" {...fields.buy_rate} />
          </FormLayout.Group>

          <FormLayout.Group>
            <TextField label="Residual value" {...fields.residual_value} />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  const [showEditFinanceSummaryModal, setShowEditFinanceSummaryModal] =
    useState(false);

  const editFinanceSummaryModal = (
    <Modal
      open={showEditFinanceSummaryModal}
      title={"Finance parameters"}
      primaryAction={{
        content: "Save",
        onAction: () => setShowEditFinanceSummaryModal(false),
      }}
      onClose={() => setShowEditFinanceSummaryModal(false)}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <TextField label="Residual value" {...fields.residual_value} />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  const errorBanner =
    submitErrors.length > 0 ? (
      <Layout.Section>
        <Banner
          tone="critical"
          title="There were some issues with your purchase option"
        >
          <ul>
            {submitErrors.map(({ message }, index) => {
              if (message) {
                return <li key={`${message}${index}`}>{message}</li>;
              } else {
                return (
                  <li key={`errorMessage${index}`}>
                    We were unable to save this option. Please try again later.
                  </li>
                );
              }
            })}
          </ul>
        </Banner>
      </Layout.Section>
    ) : null;

  const [showStructureSettingsModal, setShowStructureSettingsModal] =
    useState(false);
  const structureSettingsModal = (
    <Modal
      open={showStructureSettingsModal}
      onClose={() => setShowStructureSettingsModal(false)}
      title="Structure settings"
      primaryAction={{
        content: "Close",
        onAction: () => setShowStructureSettingsModal(false),
      }}
    >
      <Modal.Section>Settings</Modal.Section>
    </Modal>
  );

  return (
    <Page
      fullWidth={purchaseOptionType !== "net_terms"}
      backAction={{ content: "Back to opportunity", onAction: onBackAction }}
      title={
        purchaseOption && purchaseOption.id
          ? "Edit purchase option"
          : "Create purchase option"
      }
      subtitle={`for ${opportunity.attributes.name}`}
      titleMetadata={
        <SelectedIndicator selected={purchaseOption.attributes.selected} />
      }
      primaryAction={{
        content: "Save",
        onAction: submit,
        loading: isSubmitting,
      }}
      secondaryActions={[
        {
          content: purchaseOption.id ? "Cancel" : "Discard",
          onAction: onBackAction,
        },
      ]}
    >
      <Layout>
        <ApprovedBanner
          approved={purchaseOption.attributes.approved}
          purchaseOptionId={purchaseOption.id}
        />

        {errorBanner}

        <Layout.Section>
          <BlockStack gap="400">
            {purchaseOptionType !== "net_terms" && (
              <CalculatorModeToggle
                solvingMode={solvingMode}
                setSolvingMode={setSolvingMode}
              />
            )}

            {purchaseOptionType === "net_terms" && (
              <Card>
                <BlockStack gap={{ xs: "400", sm: "500" }}>
                  <Box width="100%">
                    <Text variant="bodyMd" as="p" tone="subdued">
                      {usesMilestonePayments
                        ? "Payments are a percentage of the total amount. Payments will be pending until manually scheduled according to milestones."
                        : "Payments are divided evenly and will be scheduled based on the installment payment start date."}
                    </Text>
                  </Box>

                  <InlineStack
                    gap="400"
                    align={"space-between"}
                    blockAlign={"center"}
                  >
                    <Text variant="bodyMd" as="p">
                      Payments will be{" "}
                      <b>{usesMilestonePayments ? "pending" : "scheduled"}.</b>
                    </Text>

                    <Box>
                      <InlineStack align="start">
                        <Button
                          onClick={() =>
                            handleChangePaymentStructure(
                              !usesMilestonePayments ? true : false
                            )
                          }
                          accessibilityLabel={`Change to ${
                            usesMilestonePayments ? "scheduled" : "milestone"
                          } payments`}
                        >
                          {usesMilestonePayments
                            ? "Change to scheduled"
                            : "Change to milestone"}
                        </Button>
                      </InlineStack>
                    </Box>
                  </InlineStack>
                </BlockStack>
              </Card>
            )}

            {purchaseOptionType !== "net_terms" && (
              <Card padding="400">
                <BlockStack gap="400">
                  <Text variant="headingMd" as="h6">
                    Terms
                  </Text>

                  <TimingFields
                    fields={timingFields}
                    purchaseOptionSegmentFields={purchaseOptionSegmentFields}
                  />

                  <Bleed marginInline="400">
                    <Divider />
                  </Bleed>

                  <InterestRateFields
                    fields={interestRateFields}
                    solvingMode={solvingMode}
                  />
                </BlockStack>
              </Card>
            )}

            <Card padding="400">
              <BlockStack gap="400">
                <InlineStack align="space-between">
                  <Text variant="headingMd" as="h6">
                    Structure
                  </Text>
                  {purchaseOptionType == "net_terms" && false && (
                    <Button
                      variant="plain"
                      onClick={() => setShowStructureSettingsModal(true)}
                    >
                      Settings
                    </Button>
                  )}
                </InlineStack>

                {["net_terms"].includes(purchaseOptionType) && (
                  <>
                    <DiscountFields
                      fields={discountFields}
                      totalFinanceAmount={totalFinanceAmount}
                    />

                    <Bleed marginInline="400">
                      <Divider />
                    </Bleed>

                    <DownPaymentFields
                      fields={downPaymentFields}
                      hasDownPayment={hasDownPayment}
                      setHasDownPayment={setHasDownPayment}
                      downPaymentCompletedAt={downPaymentCompletedAt}
                      usesMilestonePayments={usesMilestonePayments}
                      totalFinanceAmount={totalFinanceAmount}
                      installmentPayments={installmentPaymentFields}
                      downPaymentTransaction={
                        purchaseOption.attributes.down_payment_transaction
                      }
                      navigateToTransaction={navigateToTransaction}
                    />

                    <Bleed marginInline="400">
                      <Divider />
                    </Bleed>

                    <InstallmentPaymentFields
                      fields={installmentPaymentFields}
                      installmentPayments={installmentPayments}
                      addInstallmentPayment={addInstallmentPayment}
                      handleRemoveInstallmentPayment={
                        handleRemoveInstallmentPayment
                      }
                      removeInstallmentPayments={removeInstallmentPayments}
                      navigateToTransaction={navigateToTransaction}
                      navigateToTransactions={navigateToTransactions}
                      usesMilestonePayments={usesMilestonePayments}
                      totalFinanceAmount={totalFinanceAmount}
                      downPaymentPercent={
                        downPaymentFields?.down_payment_percent?.value
                      }
                      hasDownPayment={hasDownPayment}
                    />
                  </>
                )}

                {purchaseOptionType !== "net_terms" && (
                  <>
                    <Bleed marginInline="400">
                      <Divider />
                    </Bleed>

                    {includeDownPayment.includes(purchaseOptionType) && (
                      <DownPaymentFields
                        fields={downPaymentFields}
                        hasDownPayment={hasDownPayment}
                        setHasDownPayment={setHasDownPayment}
                        downPaymentCompletedAt={downPaymentCompletedAt}
                      />
                    )}

                    <PurchaseOptionSegmentFields
                      fields={purchaseOptionSegmentFields}
                      addPurchaseOptionSegment={addPurchaseOptionSegment}
                      removePurchaseOptionSegment={
                        handleRemovePurchaseOptionSegment
                      }
                    />

                    {(isOrganizationAdmin || isFinanceSpecialist) &&
                      includeBalloonPayment.includes(purchaseOptionType) && (
                        <BalloonPaymentFields
                          fields={balloonPaymentFields}
                          hasBalloonPayment={hasBalloonPayment}
                          setHasBalloonPayment={setHasBalloonPayment}
                          balloonPaymentCompletedAt={balloonPaymentCompletedAt}
                        />
                      )}

                    <Bleed marginInline="400">
                      <Divider />
                    </Bleed>

                    <BaseSegmentFields
                      fields={baseSegmentFields}
                      period={fields.period.value}
                      solvingMode={solvingMode}
                      setBaseTermLength={setBaseTermLength}
                      totalFinanceAmount={totalFinanceAmount}
                    />
                  </>
                )}
              </BlockStack>
            </Card>

            <OverviewCard
              invoiceTotal={invoiceTotal}
              netPayment={netPayment}
              totalFinanceAmount={totalFinanceAmount}
              termLength={totalTermLength}
              period={fields.period.value}
              purchaseOptionSegments={purchaseOptionSegments}
              purchaseOptionType={purchaseOptionType}
              discount={fields?.discount?.value}
            />
          </BlockStack>
        </Layout.Section>

        {purchaseOptionType !== "net_terms" && (
          <Layout.Section variant="oneThird">
            <BlockStack gap="400">
              {!isOrganizationAdmin &&
                !currentContactHasPermission("finance_specialist") && (
                  <SummaryCard
                    opportunity={opportunity}
                    blindDiscount={customerDiscount}
                    netPayment={netPayment}
                  />
                )}

              {!isOrganizationAdmin &&
                currentContactHasPermission("finance_specialist") && (
                  <FinanceSummaryCard
                    opportunity={opportunity}
                    buyRate={fields.buy_rate.value}
                    sellRate={fields.sell_rate.value}
                    customerInterestRate={fields.customer_interest_rate.value}
                    residualValue={fields.residual_value.value}
                    blindDiscount={customerDiscount}
                    sellRateSpread={sellRateDiscount * -1}
                    netPayment={netPayment}
                    onEditSummary={() => setShowEditFinanceSummaryModal(true)}
                  />
                )}

              {isOrganizationAdmin && (
                <AdminSummaryCard
                  opportunity={opportunity}
                  lenderBuyRate={lenderBuyRate}
                  estimatedLenderBuyRate={
                    fields.estimated_lender_buy_rate.value
                  }
                  buyRate={fields.buy_rate.value}
                  sellRate={fields.sell_rate.value}
                  customerInterestRate={fields.customer_interest_rate.value}
                  residualValue={fields.residual_value.value}
                  blindDiscount={customerDiscount}
                  buyRateSpread={buyRateDiscount * -1}
                  sellRateSpread={sellRateDiscount * -1}
                  onEditSummary={() => setShowEditAdminSummaryModal(true)}
                  netPayment={netPayment}
                />
              )}
            </BlockStack>
          </Layout.Section>
        )}
      </Layout>
      {editAdminSummaryModal}
      {editFinanceSummaryModal}
      {structureSettingsModal}
    </Page>
  );
};

PurchaseOptionCalculator.propTypes = {
  onBackAction: PropTypes.func,
  onSaveAction: PropTypes.func,
  opportunity: PropTypes.object,
  purchaseOption: PropTypes.object,
  purchaseOptionType: PropTypes.string,
  purchaseOptionSegments: PropTypes.array,
};

export default PurchaseOptionCalculator;
