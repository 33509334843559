import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, OptionList, TextField, Link } from "@shopify/polaris";

const AddTagModalComponent = (props) => {
  const {
    tags,
    isTagging,
    selectedTag,
    choiceListTags,
    openSelectTagModal,
    newVendorTagInputs,
    setOpenSelectTagModal,
    handleAddNewVendorTag,
    handleTagOpportunities,
    handleSelectedTagChange,
  } = props;

  const [categorizedTags, setCategorizedTags] = useState({});

  useEffect(() => {
    if (choiceListTags && choiceListTags.length > 0) {
      let catTags = {};
      let newTags = {};
      for (let i = 0; i < choiceListTags.length; i++) {
        let currentTag = choiceListTags[i];
        if (!Object.keys(catTags).includes(currentTag.tagType)) {
          catTags[currentTag.tagType] = [currentTag];
          newTags[currentTag.tagType] = "";
        } else {
          catTags[currentTag.tagType].push(currentTag);
        }
      }

      setCategorizedTags(catTags);
    }
  }, [choiceListTags]);

  return (
    <Modal
      open={openSelectTagModal}
      onClose={() => setOpenSelectTagModal(false)}
      title={selectedTag?.length > 0 ? "Update tags" : "Add tag"}
      loading={isTagging}
      primaryAction={{
        content: selectedTag?.length > 0 ? "Update tags" : "Add tag",
        onAction: handleTagOpportunities,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => setOpenSelectTagModal(false),
        },
      ]}
    >
      <Modal.Section>
        {Object.keys(categorizedTags).map((vendorName) => {
          let isVendor = vendorName != "admin";
          let categoryTagName = !isVendor
            ? "Available org admin"
            : `${vendorName} vendor`;

          return (
            <div key={vendorName}>
              <OptionList
                title={`${categoryTagName} tags`}
                onChange={handleSelectedTagChange}
                options={categorizedTags[vendorName]}
                selected={selectedTag}
                allowMultiple
              />

              {isVendor && (
                <div style={{ padding: "12px" }}>
                  <TextField
                    label="New Tag"
                    value={newVendorTagInputs[vendorName]}
                    onChange={(value) => {
                      handleAddNewVendorTag(value, vendorName);
                    }}
                    autoComplete="off"
                  />
                </div>
              )}

              {!isVendor && (
                <div style={{ padding: "12px" }}>
                  <Link url="/admin/settings" removeUnderline>
                    Go to tag manager
                  </Link>
                </div>
              )}

              <br />
            </div>
          );
        })}
      </Modal.Section>
    </Modal>
  );
};

AddTagModalComponent.propTypes = {
  tags: PropTypes.array,
  choiceListTags: PropTypes.array,
  isTagging: PropTypes.bool,
  selectedTag: PropTypes.any,
  openSelectTagModal: PropTypes.bool,
  newVendorTagInputs: PropTypes.object,
  setOpenSelectTagModal: PropTypes.func,
  handleAddNewVendorTag: PropTypes.func,
  handleSelectedTagChange: PropTypes.func,
  handleTagOpportunities: PropTypes.func,
};

export default AddTagModalComponent;
